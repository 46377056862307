import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Skeleton, Tooltip } from "@mui/material";
import styles from "./ResponseDetail.module.css";
import AddEnquiryModal from "../../../../Nurture/components/AddEnquiryModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import NewFormTextInput from "../../../../../../components/FormTextInput/NewFormTextInput";
import { useSelector } from "react-redux";
import LeadCourseDetails from "../../../../../../components/LeadPopup/LeadCourseDetails";
import {
  getLeadById,
  getLeadListByInstituteAndContact,
  resetLeadCreateSuccess,
  resetLeadListByContact,
  resetLeadListSuccess,
  setLeadModalState,
} from "../../../../Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import PuffLoader from "react-spinners/PuffLoader";
import LeadMainDetailsBox from "../../../../../../components/LeadMainDetailsCard/LeadMainDetailsBox";
import moment from "moment";
import {
  checkSubDomainAccess,
  getSubDomainAccessStatus,
} from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useLocation } from "react-router-dom";

const ResponseDetail = (props: any) => {
  const {
    GetBranchList,
    GetCourseList,
    GetBatchList,
    GetNurtureLeadBoard,
    CreateEnquiry,
    GetMailDetailsById,
    mailById,
    GetMailEntriesByInstitute,
    mailList,
    GetLeadById,
    emailDetailsLoading,
    GetInstituteById,
    GetCourseById,
    GetBatchById,
    getFormResponseData,
    GetFormResponse,
    GetLeadListByInstituteAndContact,
    CreateSource,
    getStudentContactByIdSuccess,
    getStudentContactByIdLoading,
    getStudentContactByIdError,
    GetStudentContactById,
    formId,
    setOpenResponseDetails,
    emailForQuery,
    setEmailForQuery,
    selectWebform,
    openResponseDetail,
    GetWebformForInstitute,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadCardView = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    userById?.rolePermission
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    userById?.rolePermission
  );

  const { getFormByInstituteData, getStudentContactByIdData } = useSelector(
    (state: any) => state.webform
  );
  const branchList = useSelector((state: any) => state.branch.branchList);
  const courseList: any = useSelector((state: any) => state.courses.courses);
  const batches = useSelector((state: any) => state.batch.batchList);
  const batchList = batches?.filter(
    (item: any) => item?.batchConductType === "REGULAR_BATCH"
  );
  const leadBoard = useSelector((state: any) => state.nurture.leadBoard);
  const leadSuccess = useSelector(
    (state: any) => state.nurture.leadCreateSuccess
  );
  const leadListByContactData = useSelector(
    (state: any) => state.nurture.leadListByContact
  );

  const {
    leadUpdateSuccess,
    leadById,
    leadByIdLoading,
    leadLostUpdateSuccess,
    leadStatusUpdateSuccess,
  } = useSelector((state: any) => state.nurture);
  const [fieldsName, setFieldsName] = useState<any>();
  const [responseId, setResponseId] = useState<any>();

  const [formDetails, setFormDetails] = useState<any>();
  const [dataForEnquiry, setDataForEnquiry] = useState<any>();
  const [selectedLeadId, setSelectedLeadId] = React.useState<string>("");
  const [selectedLeadData, setSelectedLeadData] = React.useState<any>("");
  const [leadListArray, setLeadListArray] = React.useState<any>(null);

  const sortedSwimlane =
    leadBoard &&
    [...leadBoard?.swimlaneData].sort((a: any, b: any) => {
      return a?.orderIndex - b?.orderIndex;
    });

  const coursesList = courseList?.filter(
    (item: any) => item?.status === "ACTIVE"
  );

  function handleGoBack() {
    const pathname = window.location.pathname;
    const parts = pathname.split("/");
    const webformIdIndex = parts.indexOf("webformId");
    const webformId = webformIdIndex !== -1 ? parts[webformIdIndex + 1] : null;
    const formType = getFormByInstituteData?.type;
    const formBranch = getFormByInstituteData?.branchId;

    if (location.pathname.includes("/app/Inbound/Contacts")) {
      let url = `/app/Inbound/Contacts?ContactTab=webform&webformId=${webformId}`;
      if (formType) {
        url += `&formType=${formType}`;
      }

      if (formType === "BRANCH_SPECIFIC" && formBranch) {
        url += `&formBranch=${formBranch}`;
      }
      navigate(url);
    } else {
      let url = `/app/Inbound/Webforms?webformTab=webformResponses&webformId=${webformId}`;
      if (formType) {
        url += `&formType=${formType}`;
      }

      if (formType === "BRANCH_SPECIFIC" && formBranch) {
        url += `&formBranch=${formBranch}`;
      }
      navigate(url);
    }

    setOpenResponseDetails(false);
    dispatch(resetLeadListByContact());
    setSelectedLeadData("");
    setSelectedLeadId("");
    setLeadListArray(null);
  }

  const findNamesbyId = (fieldName: any, id: string) => {
    if (fieldName === "COURSE" && id) {
      const courseName =
        courseList &&
        courseList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );

      return courseName && courseName[0]?.courseName;
    } else if (fieldName === "BATCH" && id) {
      const batchName =
        batchList &&
        batchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return batchName && batchName[0]?.batchName;
    } else if (fieldName === "BRANCH" && id) {
      const branchName =
        branchList &&
        branchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return branchName && branchName[0]?.branchName;
    }
  };

  const findItembyId = (fieldName: any, id: any) => {
    if (fieldName === "COURSE" && id) {
      const result = courseList?.find(
        (course: any) =>
          course.id.toString().toLowerCase() === id.toString().toLowerCase()
      );
      return result;
    } else if (fieldName === "BATCH" && id) {
      const result = batchList?.find(
        (batch: any) =>
          batch.id.toString().toLowerCase() === id.toString().toLowerCase()
      );
      return result;
    } else if (fieldName === "BRANCH" && id) {
      const result = branchList?.find(
        (branch: any) =>
          branch.id.toString().toLowerCase() === id.toString().toLowerCase()
      );
      return result;
    }
  };

  useEffect(() => {
    if (leadById) {
      setSelectedLeadId(leadById?.id);
      setSelectedLeadData(leadById);
      setLeadListArray([leadById]);
    }
  }, [leadById, setSelectedLeadData, setSelectedLeadId]);

  useEffect(() => {
    if (leadSuccess !== true && leadSuccess !== false) {
      const pathname = window.location.pathname;
      const parts = pathname.split("/");
      const webformIdIndex = parts.indexOf("webformId");
      const webformId =
        webformIdIndex !== -1 ? parts[webformIdIndex + 1] : null;
      const userFromLocalStorage = authUser?.institute[0];
      const lastEvaluatedKey = "";
      const limit = 8;
      const lastEvaluatedSortKey = "";
      type formResponseData = {
        instituteId: string;
        formId: string;
        limit: number;
        branchId: string;
        lastEvaluatedKey: string;
        lastEvaluatedSortKey: string;
      };
      const data: formResponseData = {
        instituteId: userFromLocalStorage?.instituteId,
        formId: webformId ? webformId : "",
        limit: 8,
        branchId: "",
        lastEvaluatedKey: "",
        lastEvaluatedSortKey: "",
      };
      if (userFromLocalStorage && formId) {
        GetFormResponse(data);
        // GetFormResponse(
        //   userFromLocalStorage?.instituteId,
        //   lastEvaluatedKey,
        //   limit,
        //   lastEvaluatedSortKey,
        //   webformId
        // );
      }

      const responseIdUrl = pathname.substring(pathname.lastIndexOf("/") + 1);
      GetStudentContactById(responseIdUrl);
    }

    dispatch(resetLeadCreateSuccess());
  }, [GetFormResponse, leadSuccess]);

  useEffect(() => {
    setDataForEnquiry(getStudentContactByIdData);
    const isOtherDetailsEmpty =
      getStudentContactByIdData &&
      Object.keys(getStudentContactByIdData.otherDetails).length === 0;

    const transformedData = [
      {
        type: "Name",
        value:
          getStudentContactByIdData &&
          getStudentContactByIdData?.firstName +
            " " +
            getStudentContactByIdData?.lastName,
      },
      {
        type: "Email",
        value: getStudentContactByIdData && getStudentContactByIdData?.email,
      },
      {
        type: "Contact",
        value:
          getStudentContactByIdData && getStudentContactByIdData?.contactNumber,
      },
      {
        otherDetails:
          getStudentContactByIdData && getStudentContactByIdData?.otherDetails,
      },
    ];

    const transformedDataWithoutOtherDetails = [
      {
        type: "Name",
        value:
          getStudentContactByIdData &&
          getStudentContactByIdData?.firstName +
            " " +
            getStudentContactByIdData?.lastName,
      },
      {
        type: "Email",
        value: getStudentContactByIdData && getStudentContactByIdData?.email,
      },
      {
        type: "Contact",
        value:
          getStudentContactByIdData && getStudentContactByIdData?.contactNumber,
      },
    ];

    setFormDetails(
      isOtherDetailsEmpty ? transformedDataWithoutOtherDetails : transformedData
    );
  }, [getStudentContactByIdData, leadSuccess]);

  const newFieldNames =
    fieldsName &&
    fieldsName?.filter(
      (item: any) => !["name", "email", "phone"].includes(item.fieldName)
    );

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const pathname = window.location.pathname;
    const responseIdUrl = pathname.substring(pathname.lastIndexOf("/") + 1);
    const startIndex = pathname.indexOf("webformId/") + "webformId/".length;
    const endIndex = pathname.indexOf("/response", startIndex);
    const webformId = pathname.substring(startIndex, endIndex);
    setResponseId(responseIdUrl);
    GetWebformForInstitute(userFromLocalStorage?.instituteId, webformId);
    GetStudentContactById(responseIdUrl);
  }, []);

  useEffect(() => {
    if (
      getStudentContactByIdData &&
      getStudentContactByIdData?.tag === "LEAD"
    ) {
      dispatch<any>(getLeadById(getStudentContactByIdData?.enquiryId));
    }
  }, [getStudentContactByIdData]);

  useEffect(() => {
    if (
      (leadUpdateSuccess !== true && leadUpdateSuccess !== false) ||
      (leadStatusUpdateSuccess !== true && leadStatusUpdateSuccess !== false) ||
      (leadLostUpdateSuccess !== true && leadLostUpdateSuccess !== false)
    ) {
      dispatch<any>(getLeadById(getStudentContactByIdData?.enquiryId));
    }
  }, [leadUpdateSuccess, leadStatusUpdateSuccess, leadLostUpdateSuccess]);

  React.useEffect(() => {
    if (getFormByInstituteData) {
      setFieldsName(
        getFormByInstituteData?.fields?.map((d: any) => {
          return { fieldName: d?.fieldName, fieldType: d?.DBModel };
        })
      );
    }
  }, [getFormByInstituteData]);

  function getFormattedDate(date: any) {
    return moment(date)?.format("MMM Do YYYY, hh:mm A");
  }

  const leadPopupApiCall = React.useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );

  const openLeadExpandModal = useCallback(
    (instituteId: string, phone: string, email: string, id: string) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "overview");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    []
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        borderRadius: "8PX",
        border: "1px solid rgb(233, 233, 233)",
        overflowX: "hidden",
        maxHeight: "calc(100vh - 93px)",
        overflowY: "hidden",
      }}
    >
      <>
        {responseId === getStudentContactByIdData?.id ? (
          <>
            <Box
              sx={{
                width: "calc(100% - 430px)",
                display: "flex",
                flexDirection: "column",
                maxHeight: "100%",
                minHeight: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px 30px",
                  overflowY: "auto",
                  overflowX: "auto",
                }}
              >
                <Box
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      paddingBottom: "19px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <button
                      className={styles.completedTaskPageBackBtn}
                      onClick={() => {
                        handleGoBack();
                      }}
                    >
                      <ArrowBackIcon
                        sx={{
                          marginRight: "8px",
                          fontSize: "15PX",
                          color: "#667085",
                        }}
                      />
                      Back
                    </button>
                  </Box>
                  <div className={styles.formDate}>
                    {dataForEnquiry?.createdDate
                      ? getFormattedDate(dataForEnquiry?.createdDate)
                      : ""}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {formDetails &&
                      formDetails.map((value: any, index: number) => {
                        if (
                          value?.type === "Name" ||
                          value?.type === "Email" ||
                          value?.type === "Contact"
                        ) {
                          return (
                            <div style={{ marginTop: "20px" }}>
                              <NewFormTextInput
                                key={index}
                                disabled={true}
                                value={value?.value}
                                label={value?.type}
                                variant="standard"
                                fullWidth
                                require={true}
                                inputProps={{
                                  sx: {
                                    "&.MuiInputBase-input.MuiOutlinedInput-input":
                                      {
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      },
                                  },
                                  maxLength: 1000,
                                }}
                                style={{ width: "50%" }}
                              />
                            </div>
                          );
                        } else if (
                          value?.otherDetails &&
                          value?.otherDetails?.length > 0
                        ) {
                          return value?.otherDetails?.map(
                            (others: any, index: number) => (
                              <div key={index} style={{ marginTop: "20px" }}>
                                {Object.keys(others).map((key: any) => {
                                  const value = others[key];
                                  const displayValue =
                                    typeof value === "object"
                                      ? JSON.stringify(value)
                                      : value;

                                  return (
                                    <div key={key}>
                                      {Array.isArray(value) ? (
                                        <>
                                          <div
                                            style={{
                                              fontSize: "12px",
                                              color: "#142C44",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {key.charAt(0).toUpperCase() +
                                              key.slice(1)?.toLowerCase()}
                                          </div>
                                          {value?.map((d: any) => {
                                            return (
                                              <div
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                }}
                                              >{`-> ${d}`}</div>
                                            );
                                          })}
                                        </>
                                      ) : value?.length === 36 ? (
                                        <NewFormTextInput
                                          disabled={true}
                                          value={findNamesbyId(
                                            newFieldNames?.filter(
                                              (d: any) => d?.fieldName === key
                                            )[0]?.fieldType,
                                            value
                                          )}
                                          label={
                                            key.charAt(0).toUpperCase() +
                                            key.slice(1)?.toLowerCase()
                                          }
                                          variant="standard"
                                          fullWidth
                                          inputProps={{
                                            sx: {
                                              "&.MuiInputBase-input.MuiOutlinedInput-input":
                                                {
                                                  fontWeight: "400",
                                                  fontSize: "12px",
                                                },
                                            },
                                            maxLength: 1000,
                                          }}
                                          style={{ width: "50%" }}
                                        />
                                      ) : (
                                        key !== "domainName" && (
                                          <NewFormTextInput
                                            disabled={true}
                                            value={value}
                                            label={
                                              key !== "domainName" &&
                                              key.charAt(0).toUpperCase() +
                                                key.slice(1)?.toLowerCase()
                                            }
                                            variant="standard"
                                            fullWidth
                                            inputProps={{
                                              sx: {
                                                "&.MuiInputBase-input.MuiOutlinedInput-input":
                                                  {
                                                    fontWeight: "400",
                                                    fontSize: "12px",
                                                  },
                                              },
                                              maxLength: 1000,
                                            }}
                                            style={{ width: "50%" }}
                                          />
                                        )
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            )
                          );
                        }
                      })}
                  </div>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "430px",
                height: "calc(100vh - 90px)",
                maxHeight: "100%",
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                borderLeft: "1px solid rgb(233, 233, 233)",
                position: "relative",
                padding: "0",
              }}
            >
              {getStudentContactByIdData?.tag === "LEAD" && leadListArray && (
                <>
                  <div
                    style={{
                      maxHeight: "calc(100vh - 170px)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          padding: "14px",
                          borderBottom: "1px solid #ebebeb",
                        }}
                      >
                        {!leadByIdLoading &&
                        leadListArray?.length > 0 &&
                        sortedSwimlane !== null ? (
                          <LeadMainDetailsBox
                            leadById={leadListArray && selectedLeadData}
                          />
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: "6px",
                              }}
                            >
                              <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                              />
                            </div>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={30}
                              sx={{ marginBottom: "6px" }}
                            />
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={40}
                              sx={{ marginBottom: "6px" }}
                            />
                          </>
                        )}
                      </div>

                      <div
                        style={{
                          padding: "14px",
                          maxHeight: "calc(100vh - 376px)",
                          overflowY: "auto",
                          zIndex: 999,
                        }}
                      >
                        {!leadByIdLoading &&
                        leadListArray?.length > 0 &&
                        selectedLeadData &&
                        sortedSwimlane !== null ? (
                          <LeadCourseDetails
                            leadById={leadListArray && selectedLeadData}
                            branchList={branchList}
                            batchList={batchList}
                            leadListByContact={leadListArray}
                            leadStages={sortedSwimlane}
                            isFromWebform={true}
                            setSelectedLeadId={setSelectedLeadId}
                            setSelectedLeadData={setSelectedLeadData}
                            selectedLeadId={selectedLeadId}
                            selectedLeadData={leadListArray && selectedLeadData}
                          />
                        ) : (
                          Array.from(new Array(3)).map((_, index) => (
                            <div key={index} style={{ marginBottom: "8px" }}>
                              <div className={styles.leadCourseSkeletonWrap}>
                                <Skeleton
                                  animation="wave"
                                  variant="rounded"
                                  height={60}
                                />
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      width: "100%",
                      left: "0px",
                      right: "0px",
                      padding: "10px 20px",
                      background: "#fff",
                      borderTop: "1px solid #ebebeb",
                    }}
                    className={styles.LeadViewCardButtonWrap}
                  >
                    {!leadByIdLoading &&
                    leadListArray?.length > 0 &&
                    sortedSwimlane !== null ? (
                      <Tooltip
                        arrow
                        title={
                          hasLeadCardView === false ||
                          (!hasAllBranchAccess &&
                            selectedLeadData?.branchId !==
                              authUser?.institute?.[0]?.branchId)
                            ? "Oops! No permission."
                            : ""
                        }
                        sx={{ width: "100%" }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#101828",
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "8px 12px",
                              "& .MuiTooltip-arrow": {
                                color: "#101828",
                              },
                            },
                          },
                        }}
                      >
                        <span>
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            sx={{ textTransform: "capitalize" }}
                            onClick={() =>
                              openLeadExpandModal(
                                selectedLeadData?.instituteId,
                                selectedLeadData?.studentContact,
                                selectedLeadData?.studentEmail,
                                selectedLeadData?.id
                              )
                            }
                            disabled={
                              !hasLeadCardView ||
                              (!hasAllBranchAccess &&
                                selectedLeadData?.branchId !==
                                  authUser?.institute?.[0]?.branchId)
                            }
                          >
                            View Lead Card
                          </Button>
                        </span>
                      </Tooltip>
                    ) : (
                      <>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          height={30}
                          sx={{ marginBottom: "6px" }}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </Box>
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: "8PX",
              border: "1px solid rgb(233, 233, 233)",
              overflowX: "hidden",
              height: "100vh",
            }}
          >
            <PuffLoader color="#36d7b7" />
          </Box>
        )}
      </>
    </Box>
  );
};
export default ResponseDetail;
